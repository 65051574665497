exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-form-submission-jsx": () => import("./../../../src/pages/contact/form-submission.jsx" /* webpackChunkName: "component---src-pages-contact-form-submission-jsx" */),
  "component---src-pages-news-and-insights-company-info-jsx": () => import("./../../../src/pages/news-and-insights/company-info.jsx" /* webpackChunkName: "component---src-pages-news-and-insights-company-info-jsx" */),
  "component---src-pages-news-and-insights-doc-request-jsx": () => import("./../../../src/pages/news-and-insights/doc-request.jsx" /* webpackChunkName: "component---src-pages-news-and-insights-doc-request-jsx" */),
  "component---src-templates-about-ascend-jsx": () => import("./../../../src/templates/about-ascend.jsx" /* webpackChunkName: "component---src-templates-about-ascend-jsx" */),
  "component---src-templates-alachua-jsx": () => import("./../../../src/templates/alachua.jsx" /* webpackChunkName: "component---src-templates-alachua-jsx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-advances-in-sterilization-technologies-for-overcoming-viral-vector-manufacturing-challenges-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/advances-in-sterilization-technologies-for-overcoming-viral-vector-manufacturing-challenges.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-advances-in-sterilization-technologies-for-overcoming-viral-vector-manufacturing-challenges-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-advancing-aav-production-with-high-throughput-screening-transcriptomics-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/advancing-aav-production-with-high-throughput-screening-transcriptomics.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-advancing-aav-production-with-high-throughput-screening-transcriptomics-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-alliance-for-regenerative-medicine-arm-list-of-approved-regenerative-medicines-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/alliance-for-regenerative-medicine-arm-list-of-approved-regenerative-medicines.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-alliance-for-regenerative-medicine-arm-list-of-approved-regenerative-medicines-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-ascend-partners-with-industry-to-drive-down-gene-therapy-cost-for-better-patient-access-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/ascend-partners-with-industry-to-drive-down-gene-therapy-cost-for-better-patient-access.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-ascend-partners-with-industry-to-drive-down-gene-therapy-cost-for-better-patient-access-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-building-reliability-into-scale-up-targeted-optimization-for-aav-production-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/building-reliability-into-scale-up-targeted-optimization-for-aav-production.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-building-reliability-into-scale-up-targeted-optimization-for-aav-production-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-cdmo-cmo-mid-year-review-tracking-the-key-moves-thus-far-in-2023-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/cdmo-cmo-mid-year-review-tracking-the-key-moves-thus-far-in-2023.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-cdmo-cmo-mid-year-review-tracking-the-key-moves-thus-far-in-2023-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-cell-and-gene-therapy-manufacturing-the-next-generation-of-startups-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/cell-and-gene-therapy-manufacturing-the-next-generation-of-startups.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-cell-and-gene-therapy-manufacturing-the-next-generation-of-startups-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-contracting-out-frees-sponsors-up-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/contracting-out-frees-sponsors-up.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-contracting-out-frees-sponsors-up-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-cphi-trends-in-outsourcing-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/cphi-trends-in-outsourcing.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-cphi-trends-in-outsourcing-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-design-of-experiment-doe-based-optimization-of-full-aav-particle-enrichment-using-stunner-as-a-high-throughput-analysis-tool-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/design-of-experiment-doe-based-optimization-of-full-aav-particle-enrichment-using-stunner®-as-a-high-throughput-analysis-tool.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-design-of-experiment-doe-based-optimization-of-full-aav-particle-enrichment-using-stunner-as-a-high-throughput-analysis-tool-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-enhancing-aav-production-using-high-throughput-screens-and-rational-design-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/enhancing-aav-production-using-high-throughput-screens-and-rational-design.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-enhancing-aav-production-using-high-throughput-screens-and-rational-design-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-ensuring-quality-in-contracted-support-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/ensuring-quality-in-contracted-support.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-ensuring-quality-in-contracted-support-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-gene-therapy-cdmos-play-critical-role-in-enabling-aav-process-improvement-standardization-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/gene-therapy-cdmos-play-critical-role-in-enabling-aav-process-improvement-standardization.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-gene-therapy-cdmos-play-critical-role-in-enabling-aav-process-improvement-standardization-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-how-can-bio-pharmaceutical-companies-contribute-to-improving-global-access-to-essential-medications-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/how-can-bio-pharmaceutical-companies-contribute-to-improving-global-access-to-essential-medications.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-how-can-bio-pharmaceutical-companies-contribute-to-improving-global-access-to-essential-medications-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-in-the-lab-at-badass-with-mo-mandegar-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/in-the-lab-at-badass-with-mo-mandegar.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-in-the-lab-at-badass-with-mo-mandegar-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-proprietary-cell-line-development-for-high-titer-aav-manufacturing-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/proprietary-cell-line-development-for-high-titer-aav-manufacturing.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-proprietary-cell-line-development-for-high-titer-aav-manufacturing-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-solving-the-aav-scalability-challenge-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/solving-the-aav-scalability-challenge.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-solving-the-aav-scalability-challenge-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-taking-full-advantage-of-service-providers-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/taking-full-advantage-of-service-providers.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-taking-full-advantage-of-service-providers-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-the-deep-roots-of-gene-therapy-in-florida-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/the-deep-roots-of-gene-therapy-in-florida.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-the-deep-roots-of-gene-therapy-in-florida-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-the-growing-future-of-drug-manufacturing-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/the-growing-future-of-drug-manufacturing.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-the-growing-future-of-drug-manufacturing-mdx" */),
  "component---src-templates-article-item-jsx-content-file-path-content-insight-the-importance-of-aav-process-comparability-throughout-development-mdx": () => import("./../../../src/templates/article-item.jsx?__contentFilePath=/opt/build/repo/content/insight/the-importance-of-aav-process-comparability-throughout-development.mdx" /* webpackChunkName: "component---src-templates-article-item-jsx-content-file-path-content-insight-the-importance-of-aav-process-comparability-throughout-development-mdx" */),
  "component---src-templates-article-listing-jsx": () => import("./../../../src/templates/article-listing.jsx" /* webpackChunkName: "component---src-templates-article-listing-jsx" */),
  "component---src-templates-board-directors-jsx": () => import("./../../../src/templates/board-directors.jsx" /* webpackChunkName: "component---src-templates-board-directors-jsx" */),
  "component---src-templates-careers-jsx": () => import("./../../../src/templates/careers.jsx" /* webpackChunkName: "component---src-templates-careers-jsx" */),
  "component---src-templates-contact-us-jsx": () => import("./../../../src/templates/contact-us.jsx" /* webpackChunkName: "component---src-templates-contact-us-jsx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-advanced-therapies-europe-ate-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/advanced-therapies-europe-ate.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-advanced-therapies-europe-ate-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-ascend-at-advanced-therapies-week-2024-in-miami-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/ascend-at-advanced-therapies-week-2024-in-miami.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-ascend-at-advanced-therapies-week-2024-in-miami-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-asgct-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/asgct.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-asgct-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-bio-international-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/bio-international.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-bio-international-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-bioflorida-celebration-of-biotech-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/bioflorida-celebration-of-biotech.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-bioflorida-celebration-of-biotech-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-bioprocessing-summit-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/bioprocessing-summit.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-bioprocessing-summit-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-dg-gt-conference-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/dg-gt-conference.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-dg-gt-conference-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-esact-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/esact.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-esact-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-esgct-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/esgct.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-esgct-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-meeting-on-the-med-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/meeting-on-the-med.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-meeting-on-the-med-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-meeting-on-the-mesa-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/meeting-on-the-mesa.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-meeting-on-the-mesa-mdx" */),
  "component---src-templates-event-item-jsx-content-file-path-content-events-oxford-global-gene-therapy-development-congress-mdx": () => import("./../../../src/templates/event-item.jsx?__contentFilePath=/opt/build/repo/content/events/oxford-global-gene-therapy-development-congress.mdx" /* webpackChunkName: "component---src-templates-event-item-jsx-content-file-path-content-events-oxford-global-gene-therapy-development-congress-mdx" */),
  "component---src-templates-events-listing-jsx": () => import("./../../../src/templates/events-listing.jsx" /* webpackChunkName: "component---src-templates-events-listing-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-london-jsx": () => import("./../../../src/templates/london.jsx" /* webpackChunkName: "component---src-templates-london-jsx" */),
  "component---src-templates-meet-the-team-jsx": () => import("./../../../src/templates/meet-the-team.jsx" /* webpackChunkName: "component---src-templates-meet-the-team-jsx" */),
  "component---src-templates-munich-jsx": () => import("./../../../src/templates/munich.jsx" /* webpackChunkName: "component---src-templates-munich-jsx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-acquires-gmp-manufacturing-capacity-in-alachua-florida-from-beacon-therapeutics-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/ascend-acquires-gmp-manufacturing-capacity-in-alachua-florida-from-beacon-therapeutics.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-acquires-gmp-manufacturing-capacity-in-alachua-florida-from-beacon-therapeutics-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-advanced-therapies-partners-with-ew-healthcare-partners-to-expand-united-states-capacity-and-capabilities-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/ascend-advanced-therapies-partners-with-ew-healthcare-partners-to-expand-united-states-capacity-and-capabilities.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-advanced-therapies-partners-with-ew-healthcare-partners-to-expand-united-states-capacity-and-capabilities-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-hires-scott-law-as-coo-and-continues-strengthening-foundation-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/ascend-hires-scott-law-as-coo-and-continues-strengthening-foundation.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-hires-scott-law-as-coo-and-continues-strengthening-foundation-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-launches-with-over-130-m-of-funding-as-expert-teams-merge-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/ascend-launches-with-over-130m-of-funding-as-expert-teams-merge.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-launches-with-over-130-m-of-funding-as-expert-teams-merge-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-strengthens-operations-with-two-new-executive-team-hires-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/​ascend-strengthens-operations-with-two-new-executive-team-hires.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-ascend-strengthens-operations-with-two-new-executive-team-hires-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-ast-places-next-generation-of-flexible-isolated-fill-finish-system-at-ascend-alachua-facility-for-aav-production-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/ast-places-next-generation-of-flexible-isolated-fill-finish-system-at-ascend-alachua-facility-for-aav-production.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-ast-places-next-generation-of-flexible-isolated-fill-finish-system-at-ascend-alachua-facility-for-aav-production-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-continued-investment-in-technology-flexible-capacity-includes-new-fill-finish-capabilities-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/continued-investment-in-technology-flexible-capacity-includes-new-fill-finish-capabilities.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-continued-investment-in-technology-flexible-capacity-includes-new-fill-finish-capabilities-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-mike-stella-announced-as-the-successor-to-founding-ceo-tim-funnell-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/mike-stella-announced-as-the-successor-to-founding-ceo-tim-funnell.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-mike-stella-announced-as-the-successor-to-founding-ceo-tim-funnell-mdx" */),
  "component---src-templates-news-listing-jsx": () => import("./../../../src/templates/news-listing.jsx" /* webpackChunkName: "component---src-templates-news-listing-jsx" */),
  "component---src-templates-resource-listing-jsx": () => import("./../../../src/templates/resource-listing.jsx" /* webpackChunkName: "component---src-templates-resource-listing-jsx" */),
  "component---src-templates-site-terms-jsx-content-file-path-content-site-pages-cookies-mdx": () => import("./../../../src/templates/site-terms.jsx?__contentFilePath=/opt/build/repo/content/site_pages/cookies.mdx" /* webpackChunkName: "component---src-templates-site-terms-jsx-content-file-path-content-site-pages-cookies-mdx" */),
  "component---src-templates-site-terms-jsx-content-file-path-content-site-pages-privacy-mdx": () => import("./../../../src/templates/site-terms.jsx?__contentFilePath=/opt/build/repo/content/site_pages/privacy.mdx" /* webpackChunkName: "component---src-templates-site-terms-jsx-content-file-path-content-site-pages-privacy-mdx" */),
  "component---src-templates-site-terms-jsx-content-file-path-content-site-pages-site-map-mdx": () => import("./../../../src/templates/site-terms.jsx?__contentFilePath=/opt/build/repo/content/site_pages/site-map.mdx" /* webpackChunkName: "component---src-templates-site-terms-jsx-content-file-path-content-site-pages-site-map-mdx" */),
  "component---src-templates-site-terms-jsx-content-file-path-content-site-pages-terms-mdx": () => import("./../../../src/templates/site-terms.jsx?__contentFilePath=/opt/build/repo/content/site_pages/terms.mdx" /* webpackChunkName: "component---src-templates-site-terms-jsx-content-file-path-content-site-pages-terms-mdx" */),
  "component---src-templates-what-we-do-jsx": () => import("./../../../src/templates/what-we-do.jsx" /* webpackChunkName: "component---src-templates-what-we-do-jsx" */),
  "slice---src-components-layout-footer-footer-jsx": () => import("./../../../src/components/Layout/Footer/Footer.jsx" /* webpackChunkName: "slice---src-components-layout-footer-footer-jsx" */),
  "slice---src-components-layout-header-header-jsx": () => import("./../../../src/components/Layout/Header/Header.jsx" /* webpackChunkName: "slice---src-components-layout-header-header-jsx" */)
}

